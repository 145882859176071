//引入react jsx写法的必须
import React from 'react';
//引入需要用到的页面组件 
import index from './Pages/index/index';

//引入一些模块
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
function router() {
    return (
        <Router>
            {/* <Redirect path="/" to="/index" /> */}
            <Route path="/index" component={index} />
        </Router>);
}

export default router;