// import React from 'react';
import React, { useState, useEffect } from 'react';
import './index.css';
import html2canvas from 'html2canvas';
import zsj from '../../img/zsj.jpg';
import loginbg from '../../img/loginbg.png';
import indexbg from '../../img/indexbg.png';
import mapbg from '../../img/mapbg.png';
import gxImg from '../../img/bg1.png';
import logoImg from '../../img/logo.png';
import testImg from '../../img/test.png';
import ceceImg from '../../img/cece.png';
import fdjImg from '../../img/fdj.gif';
import gdxg from '../../img/gd.gif';
import ewm from '../../img/ewm.png';
import jg1 from '../../img/j1.png';
import jg2 from '../../img/j2.png';
import jg3 from '../../img/j3.png';
import jg4 from '../../img/j4.png';
import jg5 from '../../img/j5.png';
import jg6 from '../../img/j6.png';
import jg7 from '../../img/j7.png';
import jg8 from '../../img/j8.png';
import jg9 from '../../img/j9.png';
import jg10 from '../../img/j10.png';
import jg11 from '../../img/j11.png';
import jg12 from '../../img/j12.png';
import jg13 from '../../img/j13.png';
import distance from '../../img/distance.png';
import baocun from '../../img/baocun.png';
import gl from '../../img/gl.png';
import hb from '../../img/hb.png';
import ren from '../../img/ren.png';
import yh from '../../img/yh.png';
import look from '../../img/look.png';
import gb from '../../img/gb.png';
import v1 from '../../img/v1.mov';
import v2 from '../../img/v2.mov';
import v3 from '../../img/v3.mov';
import v4 from '../../img/v3.mov';
import v5 from '../../img/v3.mov';
import v6 from '../../img/v6.mov';
import v7 from '../../img/v7.mov';
import v8 from '../../img/v8.mov';
import v9 from '../../img/v9.mov';
import v10 from '../../img/v10.mov';
import v11 from '../../img/v11.mov';
import v12 from '../../img/v12.mov';
import v13 from '../../img/v13.mov';
import Years from '../../img/Years.wav';
import mpp3 from '../../img/mpp3.png';
import mpp31 from '../../img/mpp31.png';
import jdbg from '../../img/jdbg.png';
import jdc from '../../img/jdc.png';
import jdt from '../../img/jdt.png';
import wx from 'weixin-js-sdk'

import "animate.css"
let wid1 = 15
var itmer
function App(props) {
  const { history } = props
  const [wid, setwid] = useState(1);
  const [list, setList] = useState([
    { "url": "1", "z": 109.540225, "y": 27.936532 },
    { "url": "2", "z": 109.604945, "y": 28.337416 },
    { "url": "3", "z": 109.512131, "y": 28.39047 },
    { "url": "4", "z": 113.418241, "y": 28.224744 },
    { "url": "5", "z": 112.876383, "y": 28.227591 },
    { "url": "6", "z": 112.947534, "y": 28.154546 },
    { "url": "7", "z": 113.00595, "y": 28.235559 },
    { "url": "8", "z": 113.075711, "y": 29.458321 },
    { "url": "9", "z": 113.147216, "y": 29.427119 },
    { "url": "10", "z": 113.368656, "y": 25.553314 },
    { "url": "11", "z": 112.947361, "y": 28.186311 },
    { "url": "12", "z": 113.194929, "y": 28.252203 },
    { "url": "13", "z": 113.037663, "y": 28.227175 },
  ]);
  const [vlist, setvList] = useState([v1, v2, v3, v4, v5, v6, v7, v8, v9, v10, v11, v12, v13]);
  const [ilist, setiList] = useState([jg1, jg2, jg3, jg4, jg5, jg6, jg7, jg8, jg9, jg10, jg11, jg12, jg13]);
  const [immm, setimmm] = useState("")
  const [code, setCode] = useState("")
  const [key, setIndex] = useState(1)
  const [ispage, setispage] = useState(1)
  const [zblist, setzblist] = useState([])
  const [isbol, setisbol] = useState(false)
  const [share, setshare] = useState(false)
  const [share1, setshare1] = useState(false)
  const [play, setaaa] = useState(true)
  const [timg, settimg] = useState(yh)
  const [nickname, setnickname] = useState("")


  useEffect(() => {
    audioAutoPlay();
    // navigator.geolocation.getCurrentPosition(function (position) {

    // }, function (err) {
    // }, {
    //   enableHighAcuracy: true, //位置是否精确获取
    //   timeout: 5000,            //获取位置允许的最长时间
    //   maximumAge: 1000          //多久更新获取一次位置
    // })
  }, [])

  // function ismobile() {
  //   var u = navigator.userAgent, app = navigator.appVersion;
  //   var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
  //   var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  //   if (isAndroid) {
  //   }
  //   if (isIOS) {
  //     document.getElementById("mpmg").style.display = "block";
  //     document.getElementById("mpmg1").style.display = "none";
  //   }
  // };

  useEffect(() => {
    let code = localStorage.getItem("code")
    if (code) {
      wxshare()
      fetch(`https://jssdk.mangguonews.com/openid?code=${code}&&appid=wx02d0c3a3bb31beb4`)
        // fetch(`https://jssdk.mangguonews.com/openid&&code=222&&appid=wx02d0c3a3bb31beb4`)
        .then(rep => rep.json())
        .then(res => {
          settimg(res.data.headimgurl ? res.data.headimgurl : yh)
          setnickname(res.data.nickname ? res.data.nickname : "")
          if (res.data.headimgurl) {
            getBase64(res.data.headimgurl)
          }
          localStorage.removeItem("code")
        })
    }
  }, [])


  useEffect(() => {
    getWxCode()
  }, [])

  function audioAutoPlay() {
    var audio = document.getElementById('audio_player');
    audio.play();
  }

  const getWxCode = () => {
    let bdcode = localStorage.getItem("code")
    let appid = "wx02d0c3a3bb31beb4"; //
    let code = getUrlParam("code"); //是否存在code
    let local = window.location.href;
    if (!bdcode) {
      if (code == null || code === "") {
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect`;
      } else {
        localStorage.setItem("code", code)
        window.location.replace("https://ls.mangguonews.com/index")
      }
    }
  }

  // 检查是否存在coode
  const getUrlParam = (name) => {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    let r = window.location.search.substr(1).match(reg);
    // console.log(r)
    if (r != null) {
      return unescape(r[2]);
    }
    return null;
  }

  // 背景音乐播放开关
  const playClickedImg = () => {
    let audio_player = document.getElementById('audio_player');
    if (audio_player.paused) {
      audio_player.play();
      document.getElementById("mpmg").style.display = "none";
      document.getElementById("mpmg1").style.display = "block";
    } else {
      audio_player.pause();
      document.getElementById("mpmg").style.display = "block";
      document.getElementById("mpmg1").style.display = "none";
    }
  };

  // 首页加载中
  const loding = () => {
    // audio_player.play();
    let number = Math.round(Math.random() * 90 + 38);
    let n = wid1 + number
    if (n < 296 && n > number) {
      setwid(n)
      wid1 = n
    } else {
      clearInterval()
      wid1 = 296
      setwid(296)
      clearInterval(itmer)
      setTimeout(function () {
        setispage(2)
      }, 1000)
      return;
    }
    // console.log(number,wid,wid1)
  }


  function godw() {
    setispage(3)
  }

  // 拿到数组里值最小的下标
  function getMinIndex(arr) {
    var min = arr[0];
    //声明了个变量 保存下标值
    var index = 0;
    for (var i = 0; i < arr.length; i++) {
      if (min > arr[i]) {
        min = arr[i];
        index = i;
      }
    }
    return index;
  }

  // 方法定义 lat,lng 
  function GetDistance(lat1, lng1, lat2, lng2) {
    var radLat1 = lat1 * Math.PI / 180.0;
    var radLat2 = lat2 * Math.PI / 180.0;
    var a = radLat1 - radLat2;
    var b = lng1 * Math.PI / 180.0 - lng2 * Math.PI / 180.0;
    var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) +
      Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
    s = s * 6378.137;// EARTH_RADIUS;
    s = ((s * 10000) / 10000).toFixed(1)
    // console.log(s)
    return s;
  }

  const onlook = () => {
    setisbol(false)
  }

  const clook = () => {
    let audio_player = document.getElementById('audio_player');
    document.getElementById("mpmg").style.display = "block";
    document.getElementById("mpmg1").style.display = "none";
    audio_player.pause();
    setisbol(true)
  }

  const clookimg = () => {
    setisbol(true)
  }

  function makeImg(e) {
    setshare1(true)
    setTimeout(() => {
      html2canvas(window.document.querySelector("#capture")).then(canvas => {
        var url = canvas.toDataURL();//图片地址
        // console.log(url)
        setimmm(url)
        setshare(true)
        setshare1(false)
        // window.document.body.appendChild(canvas);
      });
    }, 5)
  }

  function getBase64(imgUrl) {
    window.URL = window.URL || window.webkitURL;
    var xhr = new XMLHttpRequest();
    xhr.open("get", imgUrl, true);
    // 至关重要
    xhr.responseType = "blob";
    xhr.onload = function () {
      if (this.status == 200) {
        //得到一个blob对象
        var blob = this.response;
        // console.log("blob", blob)
        // 至关重要
        let oFileReader = new FileReader();
        oFileReader.onloadend = function (e) {
          // 此处拿到的已经是 base64的图片了
          let base64 = e.target.result;
          // console.log("方式一》》》》》》》》》", base64)
          settimg(base64)
        };
        oFileReader.readAsDataURL(blob);
        //====为了在页面显示图片，可以删除====
        var img = document.createElement("img");
        img.onload = function (e) {
          window.URL.revokeObjectURL(img.src); // 清除释放
        };
        let src = window.URL.createObjectURL(blob);
        img.src = src
        //document.getElementById("container1").appendChild(img);
        //====为了在页面显示图片，可以删除====

      }
    }
    xhr.send();
  }


  function gbimg() {
    setshare(false)
    setshare1(false)
  }

  // gif跳转
  useEffect(() => {
    if (ispage == 3) {
      setTimeout(function () {
        setispage(4)
      }, 2540)
    }
    if (ispage == 4) {
      setTimeout(function () {
        setispage(5)
        if (key !== 3 || key !== 4) {
          // clook()
        }
      }, 2540)
    }
  }, [ispage])

  // 微信分享
  function wxshare() {
    // var signUrl = "https://ls.mangguonews.com/index";
    var signUrl = window.location.href.split('#')[0];
    fetch(`https://ls.mangguonews.com/lsjssdk/wxSign?url=${signUrl}&appid=wx02d0c3a3bb31beb4`)
      .then(rep => rep.json())
      .then(res => {
        // console.log("wx.config() ---> 接收后台返回的参数");
        wx.config({
          debug: false,
          appId: res.data.appid,
          timestamp: res.data.timestamp,
          nonceStr: res.data.noncestr,
          signature: res.data.signature,
          jsApiList: [
            'updateTimelineShareData',
            'updateAppMessageShareData',
            'getLocation',
          ]
        });
        wx.ready(function () {
          itmer = setInterval(loding, 999)
          audioAutoPlay();
          //需在用户可能点击分享按钮前就先调用
          wx.updateAppMessageShareData({
            title: 'H5 | 总书记离你有多近', // 分享标题
            desc: '习近平总书记曾多次考察湖南，测一测总书记离你有多近', // 分享描述
            link: 'https://ls.mangguonews.com/index', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'https://ls.mangguonews.com/y4cddr7PNtusivn5QrkM.png', // 分享图标
            success: function () {
              // console.log("分享朋友配置好了")
            }
          })
          // 分享到朋友圈
          wx.updateTimelineShareData({
            title: 'H5 | 总书记离你有多近', // 分享标题
            desc: '习近平总书记曾多次考察湖南，测一测总书记离你有多近', // 分享描述
            link: 'https://ls.mangguonews.com/index', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'https://ls.mangguonews.com/y4cddr7PNtusivn5QrkM.png', // 分享图标
            success: function () {
              // console.log("分享朋友圈配置好了")
            }
          })
          wx.getLocation({
            type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
            success: function (res) {
              var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
              var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
              var speed = res.speed; // 速度，以米/每秒计
              var accuracy = res.accuracy; // 位置精度
              let newlist = []
              list.forEach(e => {
                newlist.push(GetDistance(longitude,latitude, e.z, e.y))
              });
              let kesy = getMinIndex(newlist)
              setIndex(kesy)
              setzblist(newlist)
            }
          });
      
        });
      })
  }


  return (
    <div style={{ height: 100, width: "100%" }}>
      {/* 喇叭 */}
      <div id='audioBox'>
        <audio id="audio_player" loop>
          <source src={Years} />
        </audio>
      </div>
      <div style={{ position: "fixed", top: 20, right: 20, zIndex: 1 }} onClick={playClickedImg}>
        <img id="mpmg" alt="" style={{ height: 25, width: 25, display: "none" }} src={mpp31}></img>
        <img id="mpmg1" alt="" style={{ height: 25, width: 25 }} src={mpp3}></img>
      </div>
      {/* 加载 */}
      <div className="login" id="logini" style={{ backgroundImage: 'url("' + loginbg + '")', display: ispage == 1 ? "flex" : "none" }}>
        <div>
          <div class="animate__animated animate__pulse login_img1">
            <img alt="logo" src={jdt}></img>
          </div>
          <div className="login_img2" style={{ backgroundImage: 'url("' + jdbg + '")' }}>
            {/* <div className="login_img2-k" style={{ width: wid }}></div> */}
            <div className="login_dd" style={{ width: wid }}>
              <img alt="进度条" src={jdc}></img>
            </div>
          </div>
          <div className="login_img3">
          </div>
        </div>
      </div>
      {/* 第一页 */}
      <div className="index" style={{ backgroundImage: 'url("' + indexbg + '")', display: ispage == 2 ? "block" : "none" }}>
        <div className="animate__animated animate__backInDown index_img1">
          <img alt="logo" src={logoImg}></img>
        </div>
        <div className="animate__animated animate__bounceInDown index_con">
          <div className="index_img2">
            <img alt="宣言" src={testImg}></img>
          </div>
          <div className="animate__animated animate__bounceInUp index_img3">
            <img alt="测一测" src={ceceImg} onClick={godw}></img>
          </div>
        </div>
      </div>
      {/* 第二页 */}
      <div className="map" style={{ backgroundImage: 'url("' + mapbg + '")', display: ispage == 3 ? "block" : "none" }}>
        <div className="map_img1">
          <img alt="logo" src={logoImg}></img>
        </div>
        <div className="map_img2">
          <img alt="" src={fdjImg} ></img>
        </div>
        <div className="map_tos"> 正在测量中......</div>
      </div>
      {/* // 动效 */}
      <div className="excessive" style={{ backgroundImage: 'url("' + gxImg + '")', display: ispage == 4 ? "block" : "none", zIndex: 33 }}>
        <img style={{ zIndex: 33 }} alt="" src={gdxg}></img>
      </div>
      {/* 结果 */}
      <div className="detail" style={{ backgroundImage: 'url("' + gxImg + '")', display: ispage == 5 ? "block" : "none" }} id="capture">
        {/* <img alt="" src={immm}></img> */}
        {isbol ?
          <div onClick={onlook} className="detail_tost">
            {key == 4 ? <img className="detail-lookimg" alt="" src={zsj} ></img> :
              <video src="movie.ogg" muted src={vlist[key]} autoplay="autoplay" className="detail-video" width="100%" height="300" controls="controls">
                <source src="movie.ogg" type="video/ogg" />
              </video>}
          </div> : ""}
        <div className="detail_img1">
          <img alt="" src={logoImg}></img>
        </div>
        <div className="detail_con">
          {/* animate__backInUp */}
          <div className="detail_img2">
            <img className="detail_img1t" alt="" src={ilist[key]}></img>
            {key === 3 ? "" :
              share1 ?
                <img className="detail_look" alt="" onClick={clook} src={look}></img> :
                <img className="animate__animated animate__rubberBand detail_look" alt="" onClick={clook} src={look}></img>
            }
            {key === 4 ?
              <img className="animate__animated animate__rubberBand detail_look" alt="" onClick={clookimg} src={look}></img>
              : ""}
          </div>
          <div className="detail_img3">
            <img alt="" src={distance}></img>
          </div>
        </div>
        {/* 公里 */}
        <div className="detail_text">
          <div className="detail_num">{zblist[key]}</div>
          <div className="detail_gl">
            <img alt="" src={gl}></img>
          </div>
        </div>
        {/* 生成海报 */}
        {share1 ?
          <div className="detail_ewm">
            <img alt="" src={ewm}></img>
          </div> :
          <div className="detail_hb">
            <img alt="" onClick={(e) => makeImg(e)} src={hb}></img>
          </div>}
        {/* 人 */}
        <div className="detail_ren" style={{ backgroundImage: 'url("' + ren + '")' }}>
          {/* <img alt=""  className="detail_kj" src={ren}></img> */}
          <div className="detail_xmg">{nickname}</div>
          <div> <img alt="" className="detail_yh" src={timg}></img></div>
        </div>
        {/* 分享 */}
        {share ?
          <div className="detail_zz">
            <img onClick={gbimg} className="detail_gb" src={gb}></img>
            <img alt="" src={immm} style={{ height: 530 }} ></img>
            <img alt="" src={baocun} className="detail_baocun" ></img>
          </div> : ""}
      </div>
      {share1 ? <div className="detail_loding" >图片生成中...</div> : ""}
    </div>
  );
}


export default App;

