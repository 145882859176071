// import React from 'react';
import React from 'react';
import './App.css';
import Router from './Router'
class App extends React.Component {
render(){
return (
  <Router />
);
}
}




export default App;


